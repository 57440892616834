import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import Media from 'react-bootstrap/Media';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import './pages.css'
import Carousel from '../components/CarouselV';

function Events(props) {
  const history = useHistory();
  
  const eventsTitle = "Events"
  const stablesTitle = "Stables"
  const lodgingTitle = "Lodging"
  
  const eventsText = "Join us in our serene settings that can make any occasion a special occasion. Our focus is on creating a space where you can connect, relax, and enjoy your friends, family, and/or co-workers."
  const stablesText = "Located on 45 acres of rolling hills, trees, and open spaces available for you and your horses to enjoy. "
  const lodgingText = "Stay where you celebrate. Our rustic manor will feel like your home away from home. Hitching Post Manor offers a relaxing and enchanting farmhouse escape. From the balconies, you will have multiple spots for serenity while taking in spectacular views of the ranch."
  
  const [infoText, setInfoText] = useState('Text');
  const [title, setTitle] = useState('Title');
  
  useEffect(() => {
    if(history.location.state && history.location.state.info) {
      const { info } = history.location.state;
      console.log('from info page', info)
      if (info === 'events') {
        setTitle(eventsTitle)
        setInfoText(eventsText)
      } else if (info === 'stables') {
        setTitle(stablesTitle)
        setInfoText(stablesText)
      } else {
        setTitle(lodgingTitle)
        setInfoText(lodgingText)
      }
    } else {
      setTitle(eventsTitle)
      setInfoText(eventsText)
    }

  }, [])

  const handleClick = e => {
    // console.log(e.target.innerHTML)
    const text = e.target.innerHTML;

    if (text === "Events") {
      setTitle(eventsTitle)
      setInfoText(eventsText)
    } else if (text === "Stables") {
      setTitle(stablesTitle)
      setInfoText(stablesText)
    } else if (text === "Lodging") {
      setTitle(lodgingTitle)
      setInfoText(lodgingText)
    }
  }

  return (
    <Container fluid='md' className="mt-5 d-flex flex-column " style={{
      // border: '1px solid red',
    }}>
      <Row>
       {/* Fix spacing when Carousel is removed
        <Col sm={8}>  
        end spacing change for Carousel */}
        <Col>
          <Media className='info'>
            <Fade top appear={true} spy={title}>
              <Media.Body className="align-self-center mr-2">
                  <h3>{title}</h3>
                  <p className='text-justify'>{infoText}</p>
                <br/>
                <hr/>
                <br/>
                <Row className='mb-5'>
                  <Col>
                    <Button
                      variant="outline-info"
                      size='lg'
                      className='infoBTN'
                      onClick={(e) => handleClick(e)}
                    >{title === "Events" ? stablesTitle : eventsTitle}</Button>
                  </Col>
                  <Col>
                      <Button
                        variant="outline-info"
                        size='lg'
                        className='infoBTN'
                        onClick={(e) => handleClick(e)}
                      >{title === "Lodging" ? stablesTitle : lodgingTitle}</Button>
                  </Col>
                </Row>

   
              </Media.Body>
            </Fade>
          </Media>
        </Col>
    
        <Col sm={4}>
          <Carousel />
        </Col>
   
     
      </Row>
    </Container>
  );
}

export default Events;
