import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faPinterest, faYoutube, faSnapchat } from '@fortawesome/free-brands-svg-icons';

import logo from '../assets/logos/smWhite.png'
import '../App.css'

function Footer(props) {
  // email:
  // 'info@hitchingpostmanor.com'
  const email = 'info@hitchingpostmanor.com'
  const phoneNumber = '(662) 710-0095'
  
  return (
    <Container 
      className='myFooter bg-dark pt-3'
      style={{ color: '#D4D4D4'}}
      fluid
    >
        <Row>
          <Col 
            sm={4}
            // style={{border: '1px solid blue'}}
          >
            <ul className='list-unstyled flex-column'>
              <li 
              // className='justify-content-start'
              >
                <Nav.Link href="https://maps.google.com/?q=305 Jayme Lynn Ln Como, MS 38619"
                  target="_blank" 
                  rel="noopener noreferrer" 
                >305 Jayme Lynn Ln Como, MS 38619</Nav.Link>
              </li>
              <li 
              // className='justify-content-start'
              >
                <Nav.Link className="ml-3" href="tel:‭(662) 710-0095">Call us: {phoneNumber}</Nav.Link>
              </li>
              <li>
                <Navbar.Text>{email}</Navbar.Text>
              </li>
              <li>
                <Navbar.Text>© 2022 Hitching Post Manor. Developed by <a href="https://sagdi.com/"
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={{ color: '#d4d4d4'}}
                  >SF</a> and JN
                </Navbar.Text>
              </li>
            </ul>
            {/* <hr style={{backgroundColor: '#D4D4D4'}}/> */}
          </Col>


          <Col 
            sm={8}
            // style={{border: '1px solid blue'}}
          >
            <Row className='align-items-center'>
              
              {/* image logo: */}
              <Col sm 
                // style={{border: '1px solid red'}}
                className="mb-auto"
              >
                <img src={logo} alt='' style={{width: '220px', opacity: 0.7}} />
              </Col>

              {/* social */}
              <Col sm 
                // style={{border: '1px solid green'}}
                
              >
                <Row
                  // style={{border: '1px solid green'}}
                  className='justify-content-center'
                >
                  <Navbar.Text>Visit Us On Social Media:</Navbar.Text>
                </Row>

                <Row
                  className='justify-content-center'
                >
                  <Nav className='justify-content-center list-unstyled'
                    style={{fontSize: '20px'}}
                  >
                    <Nav.Link className=""
                      href="https://www.facebook.com/hitchingpostmanorevents/"
                      target="_blank" 
                      rel="noopener noreferrer" 
                    >
                      <FontAwesomeIcon icon={faFacebook} size='lg'/>
                    </Nav.Link>
                    <Nav.Link className="ml-auto" 
                      href="https://www.instagram.com/hitchingpostmanor/"
                      target="_blank" 
                      rel="noopener noreferrer" 
                    >
                      <FontAwesomeIcon icon={faInstagram} size='lg'/>
                    </Nav.Link>
                   {/* remove twitter link
                    <Nav.Link className="ml-auto" 
                      href="https://twitter.com/"
                      target="_blank" 
                      rel="noopener noreferrer" 
                    >
                      <FontAwesomeIcon icon={faTwitter} size='lg'/>
                    </Nav.Link>
                    end of removing twitter link */}
                  </Nav>
                {/* </Row>
                <Row
                  className='justify-content-center'
                > */}
                   {/* remove pinterest link
                  <Nav className='justify-content-center list-unstyled'
                    style={{fontSize: '20px'}}
                  >

                    <Nav.Link 
                      className="ml-auto" 
                      href="https://pinterest.com/hitchingpostmanor/"
                      target="_blank" 
                      rel="noopener noreferrer" 
                    >
                      <FontAwesomeIcon icon={faPinterest} size='lg'/>
                    </Nav.Link>
                     end of removing twitter link */}
                    {/* remove YouTube link
                    <Nav.Link 
                      className="ml-auto" 
                      href="https://youtube.com/hitchingpostmanor/"
                      target="_blank" 
                      rel="noopener noreferrer"   
                    >
                      <FontAwesomeIcon icon={faYoutube} size='lg'/>
                    </Nav.Link>
                   end of removing snapchat link */}
                   {/* removing snapchat link
                    <Nav.Link 
                      className="ml-auto" 
                      href="https://snapchat.com/"
                      target="_blank" 
                      rel="noopener noreferrer"   
                    >
                      <FontAwesomeIcon icon={faSnapchat} size='lg'/>
                    </Nav.Link>
                  </Nav>
                 end of removing snapchat link */}
                </Row>
                  
              </Col>
            </Row>
          </Col>



        </Row>      

       </Container>
  );
}

export default Footer;
