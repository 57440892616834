import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal'

import emailjs from 'emailjs-com';


function Contact() {
  const [email, setEmail] = useState({ 
    address: '',
    phone: '',
    subject:'General',
    name: '',
    message: ''
  });
  const [showModal, setShowModal] = useState(false);
  const [request, setRequest] = useState({
    isLoading: false,
    error: false
  });
  const [validated, setValidated] = useState(false);

  const errorMessage1 = 'Team of high skilled monkeys working to fix this.';
  const errorMessage = 'Something went wrong';
  const successMessage = 'We will get back to you shortly.';
  const emptyFieldMessage = 'Please provide us at least Email or Phone number and message text';

  const handleShow = () => {
    setShowModal(!showModal);
  }

  const handleChange = e => {
    setEmail({
      ...email,
      [e.target.name]: e.target.value,
    });
  }

  const submit = e => {
    e.preventDefault();
    // console.log('email: ', email);
    // console.log('event object: ', e.target)

    setRequest({
      isLoading: true,
      error: false
    })

    // set timeout for testing purposes:
    // setTimeout(()=> {
    //   setRequest({
    //     isLoading: false,
    //     error: false,
    //   })
    //   handleShow();
    // }, 2000)
    // console.log( process.env.REACT_APP_template_id)
    // console.log( process.env.REACT_APP_user_id)

    emailjs.sendForm('gmail', process.env.REACT_APP_template_id, e.target, process.env.REACT_APP_user_id)
      .then((result) => {
          console.log('response from email js', result.text);
          setRequest({
            isLoading: false,
            error: false,
          })
          handleShow();
      }, (error) => {
          console.log('error response from email js', error.text);
          setRequest({
            isLoading: false,
            error: true,
          })
          handleShow();
      });

    //clear form after submission
    setEmail({ 
      address: '',
      phone: '',
      subject: 'General inquiry',
      name: '',
      message: ''
    });
  }

  return (
    <Container className='justify-content-md-center mt-5'>
      <h1>Contact Us</h1>
      <br/>
      <Form onSubmit={submit}>
        <Form.Group controlId="formBasicInfo">
          <Row lg={4} className='justify-content-md-center'>
            <Col>
              <Form.Label>Subject</Form.Label>
              <Form.Control as="select"
                name='subject'
                onChange={handleChange}
                value={email.subject}
                required
              >
                <option value='General inquiry'>General inquiry</option>
                <option value='Reservations'>Reservations</option>
                <option value='Bussiness proposal'>Business event</option>
                <option value='Other'>Other</option>
              </Form.Control>
            </Col>
            <Col>
              <Form.Label>Your name</Form.Label>
              <Form.Control 
                placeholder="Name" 
                name='name'
                onChange={handleChange}
                value={email.name}
                required
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Phone or Email</Form.Label>
          <Row md={2} lg={4} className='justify-content-md-center'>
            <Col>
              <Form.Control
                type="text" placeholder="Phone number or Email address"
                name='address'
                onChange={handleChange}
                value={email.address}
                required
              />
              <Form.Text id="passwordHelpBlock" muted>
                So we can get back to you.
              </Form.Text>
            </Col>
          </Row>
        </Form.Group>

        <br/>
        <Form.Group controlId="formBasicTextarea">
          <Row lg={2} className='justify-content-md-center'>
            <Col>
              <Form.Control as='textarea' rows='4' type="text" placeholder="Message" 
                name='message'
                onChange={handleChange}
                value={email.message}
                required
              />
            </Col>
          </Row>
        </Form.Group>

        <Button
          disabled={request.isLoading}
          variant="outline-info"
          size='lg'
          className='infoBTN'
          type="submit"
        >{request.isLoading ? "Loading..." : "Submit"}
        </Button>
      </Form>

      <Modal
        size="md"
        centered
        show={showModal}
        onHide={handleShow}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {request.error ? 'Message Failed' : 'Your message is sent'} 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {request.error ? errorMessage : successMessage} <br/>
            For immediate response you can call us at: <br/> 
            (662) 710-0095
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-info"
            className='infoBTN'
            onClick={handleShow}
          >Close</Button>
        </Modal.Footer>
      </Modal>

    </Container>
  )
}

export default Contact;
