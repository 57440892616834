import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

import '../App.css'

import image1 from '../assets/images/hpm-image-v1.jpeg';
import image2 from '../assets/images/hpm-image-v2.jpeg';
import image3 from '../assets/images/hpm-image-v3.jpeg';
import image4 from '../assets/images/hpm-image-v4.jpeg';

function MyCarousel() {
  return (
    <Carousel className='shadow-lg'>
      <Carousel.Item className='carouselImages'>
        <img
          className="d-block w-100"
          src={image1}
          alt="First slide"

        />
        {/* <Carousel.Caption className="captionBG">
          <h3>The Hitching Post</h3>
          <p>This could be first statement about your business that users will see. There will be another 2 slides that you can use in similar fashion.
          </p>
        </Carousel.Caption> */}
      </Carousel.Item>

      <Carousel.Item className='carouselImages'>
        <img
          className="d-block w-100"
          src={image2}
          alt="Second slide"
        />

        {/* <Carousel.Caption className="captionBG">
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>

      <Carousel.Item className='carouselImages'>
        <img
          className="d-block w-100"
          src={image3}
          alt="Third slide"
        />

        {/* <Carousel.Caption className="captionBG">
          <h3>Third slide label</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption> */}
      </Carousel.Item>

      <Carousel.Item className='carouselImages'>
        <img
          className="d-block w-100"
          src={image4}
          alt="Forth slide"
        />

        {/* <Carousel.Caption className="captionBG">
          <h3>Third slide label</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      
  </Carousel>
  );
}

export default MyCarousel;
