import React from 'react';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './pages.css'

function Availability(props) {
  const dates = 'Now Booking for 2023 - 2024'
  return (
    <Container className="mt-5">
      <h2 className='mb-5'>Availability</h2>

      <Row className='mb-5'>
        <Col sm>
          <h3 className='mb-3'></h3>
          <p>
           
          </p>
          <p>
            
          </p>
          <p>
            
          </p>
        </Col>


        <Col sm>
          <h3 className='mb-3'>{dates}</h3>
            <p>
              
            </p>
            <p>
              Please contact us to arrange a visit, to inquire about particular dates, or to ask a question.
            </p>
        </Col>
        <Col sm>
          <h3 className='mb-3'></h3>
            <p>
              
            </p>
            <p>
              
            </p>
            <p>
              
            </p>
            <p>
              
            </p>
        </Col>
      </Row>
      <hr/>
      <Row>
        <Col sm>
          <h3 className='mb-3'></h3>
            <p>
             
            </p>
            <p>
              
            </p>
            <p>
              
            </p>
            <p>
              
            </p>
          </Col>
        <Col sm>
          <h3 className='mb-3'></h3>
          <p>
            
          </p>
          <p>
            
          </p>
        </Col>
        <Col sm>
          <h3 className='mb-3'></h3>
            <p>
              
            </p>
            <p>
              
            </p>
            <p>
              
            </p>
            <p>
              
            </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Availability;
