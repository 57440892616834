import React from 'react';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import './pages.css';

function Info(props) {

  return (
    <>
      <Container className="contain d-flex flex-column mt-4">
      {/* <br/> */}
        <br/>
        <h2 className="page-header">Spaces at Hitching Post Manor</h2>
        <br/>
        <h5 className='text-left mt-4'>
          <p><b>The Picturesque Lake</b> is a scenic photo location offering a beautiful
          ceremony space.</p>
        </h5>
        <h5 className='text-left mt-4'>
        <p><b>Trees in Pasture Parting</b> between old oak trees offers rustic elegance for your
ceremony or engagement photos.</p>
        </h5>
        <h5 className='text-left mt-4'>
        <p><b>Hitching Post Pavilion</b> is a gorgeous canvas waiting to be painted. The
pavilion overlooks the lake and pasture, and, in the evening, beautiful sunsets provide a
scenic backdrop to your event.</p>
        </h5>
        <h5 className='text-left mt-4'>
        <p><b>The Great Hall</b> is a perfect venue for an intimate reception, a family gathering, or a
corporate meeting.</p>
        </h5>

      <br/>

      <Row>
        <Col sm style={{marginBottom: '10px'}}>
          <Link to={{
            pathname: '/info-details',
            state: {info: 'events'}
          }}>
            <Button variant="outline-info" size='lg'
              className='infoBTN'
            >Events</Button>
          </Link>
        </Col>
        <Col>
          <Link to={{
              pathname: '/info-details',
              state: {info: 'stables'}
            }}>
            <Button variant="outline-info" size='lg'
              className='infoBTN'
            >Stables</Button>
          </Link>
        </Col>
        <Col>
          <Link to={{
              pathname: '/info-details',
              state: {info: 'lodging'}
            }}>
            <Button variant="outline-info" size='lg'
              className='infoBTN'
            >Lodging</Button>
          </Link>
        </Col>
      </Row>
      </Container>
      {/* <CardDeck style={{width: '90%', margin: '0 auto'}}>

        <Card className='cards'>
          <Card.Img variant="top"  style={{opacity: 0.9 }} />
          <Card.Body>
            <Card.Title>Events</Card.Title>
            <Card.Text>
              Short text for events
            </Card.Text>
            <Button variant="info">Learn more</Button>
          </Card.Body>
        </Card>
        
        <Card className='cards'>
          <Card.Img variant="top"  style={{opacity: 0.9 }} />


          <Card.Body>
            <Card.Title>Stables</Card.Title>
            <Card.Text>
            Short text for Stables {' '}
            </Card.Text>
            <Button variant="info">Learn more</Button>
          </Card.Body>
        </Card>

        <Card className='cards'>
          <Card.Img variant="top"  style={{opacity: 0.9 }} />


          <Card.Body>
            <Card.Title>Lodging</Card.Title>
            <Card.Text>
              Short text for Lodging
            </Card.Text>
            <Button variant="info">Learn more</Button>
          </Card.Body>
        </Card>
      </CardDeck> */}
    
    
    </>
  );
}

export default Info;