import React, { useState , useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Fade from 'react-reveal/Fade';
import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';

import Carousel from '../components/Carousel';
import CarouselV from '../components/CarouselV';
import thumb1 from '../assets/thumbs/eventsThumb2.jpg'
import thumb2 from '../assets/thumbs/stablesThumb2.jpg'
import thumb3 from '../assets/thumbs/lodgingThumb3.jpg'

import video from '../assets/video/HPM_video3.mp4'

function Home(props) {

  useEffect(() => {
    if(history.location.state && history.location.state.fromEvents) {
      console.log('hooray')
      goToGallery();
    }
  }, [])

  const history = useHistory();

  const galleryRef = useRef();
  const cardsRef = useRef();

  const goToCards = () => {
    window.scrollTo({ behavior: 'smooth', top: cardsRef.current.offsetTop - 100 })
  }

  const goToGallery = () => {
    window.scrollTo({ behavior: 'smooth', top: galleryRef.current.offsetTop - 20 })
  }

  const [hovered1, setHovered1] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const toggleHover1 = () => setHovered1(!hovered1);
  const toggleHover2 = () => setHovered2(!hovered2);
  const toggleHover3 = () => setHovered3(!hovered3);

  return (
    <Container style={{minHeight: '90vh'}} fluid>
      <Fade duration={2000}>

      <Row className="justify-content-md-center">
        <header> 
          <div className="overlay"></div>
          <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
            <source src={video} type="video/mp4"/>
          </video>
          <div class="container h-100">
            <div class="d-flex h-100 text-center align-items-center">
              <div class="w-100 text-white">
                <h1 style={{ opacity: 0.9 }}>
                </h1>
                <div onClick={goToCards} className="scrollBtn">
                  <FontAwesomeIcon icon={faAngleDown} size='2x'/>
                </div>
              </div>
            </div>           
          </div>
        </header>
      </Row>
      
      <Container>
        <CardDeck 
          style={{marginTop: '40px'}}
          ref={cardsRef}
        >
          {/* <Row > */}
            <Card className={`cards border-0 shadow ${hovered1 ? 'shadow-lg' : ''}`}
              onMouseEnter={toggleHover1}
              onMouseLeave={toggleHover1}
            >
              <Link to={{
                pathname: '/info-details',
                state: {info: 'events'}
              }} style={{textDecoration: 'none', color: '#343434'}}>
              <Card.Img variant="top" src={thumb1} style={{opacity: 0.9 }} />
              <Card.Body>
                <Card.Title><h3>Events</h3></Card.Title>
                <hr/>
                <Card.Text className="pt-3 font-italic">
                  Make any occasion a special one
                </Card.Text>
                {/* {hovered1 ? <Button variant="info">Learn more</Button> : null} */}
              </Card.Body>
              </Link>
            </Card>

            <Card className={`cards border-0 shadow ${hovered2 ? 'shadow-lg' : ''}`}
              onMouseEnter={toggleHover2}
              onMouseLeave={toggleHover2}
            >
              <Link to={{
                pathname: '/info-details',
                state: {info: 'stables'}
              }} style={{textDecoration: 'none', color: '#343434'}}>
              <Card.Img variant="top" src={thumb2} style={{opacity: 0.9 }} />

              <Card.Body>
                <Card.Title><h3>Stables</h3></Card.Title>
                <hr/>
                <Card.Text className="pt-3 font-italic">
                  45 acres of rolling hills, trees and open spaces {' '}
                </Card.Text>
                {/* <Button variant="info">Learn more</Button> */}
              </Card.Body>
              </Link>
            </Card>

            <Card className={`cards border-0 shadow ${hovered3 ? 'shadow-lg' : ''}`}
              onMouseEnter={toggleHover3}
              onMouseLeave={toggleHover3}
            >
              <Link to={{
                pathname: '/info-details',
                state: {info: 'lodging'}
              }} style={{textDecoration: 'none', color: '#343434'}}>
                <Card.Img variant="top" src={thumb3} style={{opacity: 0.9 }} />
                <Card.Body>
                  <Card.Title><h3>Lodging</h3></Card.Title>
                  <hr/>
                  <Card.Text className="pt-3 font-italic">
                    Stay where you celebrate
                  </Card.Text>
                  {/* <Button variant="info">Learn more</Button> */}
                </Card.Body>
              </Link>
            </Card>
          {/* </Row> */}
        </CardDeck>
      </Container>


 <Container style={{marginTop: "10%"}} ref={galleryRef} id='carousel'>
         <h3>Gallery</h3> 
        <Row>
          <Col md={8} className='mx-auto mt-2'>
            <Carousel />
          </Col>
          
          <Col md={4} className='mx-auto mt-2'>
            <CarouselV />
          </Col> 
        
        </Row>
      </Container>
 
      </Fade>
    </Container>
  );
}

export default Home;
