import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { LinkContainer } from "react-router-bootstrap";
import logo from '../assets/logos/smWhiteLogo.png'

import '../App.css';

function NavigationBar() {
  return (
      <Navbar collapseOnSelect bg="dark" variant="dark" expand='lg' sticky='top' className='myNavbar'>

        <a href='/'>
          <Navbar.Brand>
            <img src={logo} alt='' style={{width: '60px'}} />
          </Navbar.Brand>
        </a>
        
        <a href='/' style={{cursor: 'pointer'}}>
          <Navbar.Text className='mr-3 nav-heading' >Hitching Post Manor</Navbar.Text>
        </a>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">

            <LinkContainer to='/info'>
              <Nav.Link className="ml-5" >Info</Nav.Link>
            </LinkContainer>

            <LinkContainer to='/availability'>
              <Nav.Link className="ml-5" >Availability</Nav.Link>
            </LinkContainer>

            <LinkContainer to='/contact'>
              <Nav.Link className="ml-5">Contact</Nav.Link>
            </LinkContainer>
  
            <LinkContainer to='/faq'>
              <Nav.Link className="ml-5" >FAQ</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
  );
}

export default NavigationBar;
