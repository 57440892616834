import React from 'react';
import { Route } from 'react-router-dom';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './App.css';

import Home from './pages/Home';
import Info from './pages/Info';
import InfoDetailed from './pages/InfoDetailed';
import Availability from './pages/Availability'
import Faq from './pages/Faq';
import Contact from './pages/Contact';

function App() {
  return (
    <div className='App' >
      <Navbar />
        <div className='mainContent'>
          <Route exact path='/' component= {Home} />
          <Route path='/info' component= {Info} />
          <Route path='/info-details' component= {InfoDetailed} />
          <Route path='/availability' component= {Availability} />
          <Route path='/faq' component={Faq} />
          <Route path='/contact' component={Contact} />     
        </div>
      <Footer />
    </div>
  );
}

export default App;
