import React from 'react';

import Container from 'react-bootstrap/Container';
import './pages.css'

function Faq() {

  return (
    <Container className="mt-5">
      <h2 className='mb-5'>FAQ</h2>
      <p><b>How can I get more information about reservations? </b>Please visit our Contact page, send email to info@hitchingpostmanor.com, or call us at (662) 710-0095 for reservations.</p>
      <p><b>Do you have in-house photography services? </b>You have the option of bringing your own photographer or using our in-house photographer. Our in-house photographer offers experience of knowing all the best vantage points in our venue. </p>
      <p><b>Is there lodging nearby? </b>There are many options for lodging in and around Como and Sardis Lake. Lodging is also available in the main house at Hitching Post Manor. The space offers seven bedrooms and six bathrooms with ample room for large family or friend groups to relax without feeling crowded. The property also provides a heated private luxury pool, and a private stocked lake, perfect for an evening cast. </p>
      <p><b>Do you have additional services? </b>Please visit the stables and lodging information to learn more about services available at Hitching Post Manor.</p>
    </Container>
  )

}

export default Faq;
